import React from 'react';
import { LTQuestionTypes } from '../../constants/Question';
import {CloneButton, CreateButton, ExportButton, NumberField, ReferenceField, required} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import ImportButton from '../ImportButton';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  SimpleForm,
  FileInput,
  ImageField,
  FileField,
  useDataProvider,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  ImageInput,
  Create,
  SelectInput,
  ReferenceInput,
  Filter,
  AutocompleteInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Асуулт" source="txt" alwaysOn />
    <ReferenceInput label="Тест" source="quizId" reference="lt/quizzes" alwaysOn>
      <AutocompleteInput optionText={choice => choice.title ? `${choice.title} (${choice.type})` : ''} />
    </ReferenceInput>
  </Filter>
);

const QuestionActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    basePath,
    exporter,
  } = props;
  const dataProvider = useDataProvider();

  const onParseData = data => {
    dataProvider.importCSV('questions', { data: Object.values(data) });
  };

  return (
    <Toolbar>
      {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <ImportButton onParseData={onParseData} />
    </Toolbar>
  );
};

export const LTQuestionList = props => (
  <List {...props}  filters={<TextFilter/>} actions={<QuestionActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField label="Тест" source="quizId" reference="lt/quizzes">
        <TextField source="title" />
      </ReferenceField>
      <TextField label="Type" source="type" />
      <TextField label="Асуулт" source="txt" />
      <NumberField label="Section" source="section" />
      <NumberField label="Дараалал" source="order" />
      <ArrayField source="choices">
        <Datagrid>
          <TextField label="Хариулт" source="txt" />
        </Datagrid>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);
const QuestionTitle = ({ record }) => {
  const { id } = record;
  return <span>Question {record ? `"${id}"` : ''}</span>;
};

export const LTQuestionEdit = props => (
  <Edit title={<QuestionTitle />} {...props}>
    <SimpleForm>
      <CloneButton />
      <TextInput disabled source="id" />
      <ReferenceInput label="Тест" fullWidth source="quizId" reference="lt/quizzes" perPage={500} validate={[required()]}>
        <AutocompleteInput optionText={choice => `${choice.title} (${choice.type})`} />
      </ReferenceInput>
      <NumberInput label="Section" source="section" validate={[required()]} />
      <NumberInput label="Sub Section" source="subSection" />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <TextInput fullWidth label="Гарчиг" source="title" />
      <TextInput fullWidth label="Main Title" source="mainTitle" />
      <TextInput fullWidth label="Sub Title" source="subTitle" />
      <ArrayInput fullWidth source="instructions" label="Заавар">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Тайлбар" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth source="explanations" label="Өгөгдөл">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Тайлбар" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput fullWidth label="Асуулт" source="txt" multiline />

      <ImageField source="photo" label="Current photo" />
      <FormDataConsumer>
        {
          ({ formData, ...rest }) =>  formData.photo &&
            <BooleanInput label="Зураг устгах" source="shouldDeletePhoto" {...rest} />
        }
      </FormDataConsumer>
      <ImageInput label="Зураг" source="file" accept="image/*">
        <ImageField source="photo" />
      </ImageInput>

      <FileField source="audio" title="Current audio" />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.audio &&
            <BooleanInput label="Аудио устгах" source="shouldDeleteAudio" {...rest} />
        }
      </FormDataConsumer>
      <FileInput label="Аудио" source="audioFile" accept="audio/*">
        <FileField source="audio" />
      </FileInput>
      <SelectInput source="type" choices={LTQuestionTypes} validate={[required()]} />
      <ArrayInput source="choices">
        <SimpleFormIterator>
          <TextInput label="Хариулт" source="txt" />
          <BooleanInput
            label="Зөв хариулт эсэх"
            source="correctAnswer"
            default={false}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const LTQuestionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Тест" fullWidth source="quizId" reference="lt/quizzes" perPage={500} validate={[required()]}>
        <AutocompleteInput optionText={choice => `${choice.title} (${choice.type})`} />
      </ReferenceInput>
      <NumberInput label="Section" source="section" validate={[required()]} />
      <NumberInput label="Sub Section" source="subSection" />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <TextInput fullWidth label="Гарчиг" source="title" />
      <TextInput fullWidth label="Main Title" source="mainTitle" />
      <TextInput fullWidth label="Sub Title" source="subTitle" />
      <ArrayInput fullWidth source="instructions" label="Заавар">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Тайлбар" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth source="explanations" label="Өгөгдөл">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Тайлбар" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput fullWidth label="Асуулт" source="txt" multiline />
      <ImageInput label="Зураг" source="file" accept="image/*">
        <ImageField source="photo" />
      </ImageInput>
      <FileInput label="Аудио" source="audioFile" accept="audio/*">
        <FileField source="audio" />
      </FileInput>
      <SelectInput source="type" choices={LTQuestionTypes} validate={[required()]} />
      <ArrayInput source="choices">
        <SimpleFormIterator>
          <TextInput label="Хариулт" source="txt" />
          <BooleanInput label="Зөв хариулт эсэх" source="correctAnswer" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
