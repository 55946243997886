import React from 'react';
import { Admin, Resource } from 'react-admin';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import authProvider from './AuthProvider';
import './App.css';
import { MentorList, MentorEdit, MentorCreate } from './components/Mentors';
import { UserList, UserEdit, UserCreate } from './components/Users';
import { CourseList, CourseEdit, CourseCreate } from './components/Courses';
import { QuizList, QuizEdit, QuizCreate } from './components/Quizzes';
import {
  CourseSectionList,
  CourseSectionEdit,
  CourseSectionCreate,
} from './components/CourseSections';
import {
  QuestionList,
  QuestionEdit,
  QuestionCreate,
} from './components/Questions';
import {
  GamingCategoryCreate,
  GamingCategoryEdit,
  GamingCategoryList,
} from './components/GamingCategory';
import { GamingBalanceList } from './components/GamingBalance';
import { GameQuizList, GameQuizCreate, GameQuizEdit } from './components/GameQuiz';
import { GameChallengeList, GameChallengeCreate, GameChallengeEdit } from './components/GameChallenge';
import { GameLevelInstructionList, GameLevelInstructionCreate } from './components/GameLevelInstruction';
import { TagList, TagEdit, TagCreate } from './components/Tags';
import {
  ExplanationList,
  ExplanationEdit,
  ExplanationCreate,
} from './components/QuestionExplanations';
import dtProvider from './utils/dataProvider';
import { TimeslotEdit, TimeslotList, TimeslotCreate } from './components/Timeslot';
import { QuizSessionList } from './components/QuizSession';
import {
  ContentProviderList,
  ContentProviderEdit,
  ContentProviderCreate,
} from './components/ContentProvider';
import {
  ScholarshipList,
  ScholarshipEdit,
  ScholarshipCreate,
} from './components/Scholarship';
import {
  UserSubscriptionList,
  UserSubscriptionEdit,
  UserSubscriptionCreate,
} from './components/UserSubscription';
import {
  SubscriptionPlanList,
  SubscriptionPlanEdit,
  SubscriptionPlanCreate,
} from './components/SubscriptionPlan';
import { AskingMentorList, AskingMentorEdit } from './components/AskingMentor';
import {
  MentoringTimeRequestList,
  MentoringTimeRequestEdit,
} from './components/MentoringTimeRequest';
import { UniversityEdit, UniversityList, UniversityCreate } from './components/University';
import { TGQuestionList, TGQuestionEdit, TGQuestionCreate } from './components/TGQuestion';
import { GameTagList, GameTagEdit, GameTagCreate } from './components/GameTags';
import { PromoCodeList } from './components/PromoCode';
import { UserCount } from './components/UserCount';
import { QuizBunleList, QuizBundleEdit, QuizBundleCreate } from './components/QuizBundle';
import { PaymentList } from './components/Payment';
import { TransactionList, TransactionCreate } from './components/Transaction';
import { PaymentLogList } from './components/PaymentLog';
import {
  ExploreBannerList,
  ExploreBannerCreate,
  ExploreBannerEdit
} from './components/ExploreBanner';
import {
  ClassBannerList,
  ClassBannerCreate,
  ClassBannerEdit
} from './components/class/ClassBanner';
import {
  ClassChallengeBannerList,
  ClassChallengeBannerCreate,
  ClassChallengeBannerEdit
} from './components/class/ChallengeBanner';
import { NotificationList, NotificationCreate } from './components/Notification';
import { ReferralsList } from './components/Referral';
import { AdminUserList, AdminUserEdit, AdminUserCreate } from './components/AdminUser';
import { FeatureTargetList, FeatureTargetCreate } from './components/FeatureTarget';
import { ROLES } from './constants/AdminUser';
import { MentorTimeSlotList, MentorTimeSlotCreate } from './components/MentorTimeSlots';
import { MentorsQuestionsList, MentorsQuestionsEdit } from './components/MentorQuestions';
// import { JourneyList, JourneyCreate, JourneyEdit } from './components/Journey';
// import { JourneyStepList, JourneyStepCreate, JourneyStepEdit } from './components/JourneyStep';
import { CouponList, CouponCreate, CouponEdit, BulkCouponCreate } from './components/Coupon';
import {
  DeploymentConfigList,
  DeploymentConfigEdit,
  DeploymentConfigCreate,
} from './components/DeploymentConfig';
import Layout from './components/TomYoLayout';
import {
  CategoryList as SkillsCategoryList,
  CategoryCreate as SkillsCategoryCreate,
  CategoryEdit as SkillsCategoryEdit,
} from './components/skills/Category';
import {
  CreatorList as SkillsCreatorList,
  CreatorCreate as SkillsCreatorCreate,
  CreatorEdit as SkillsCreatorEdit,
} from './components/skills/Creator';
import {
  CourseList as SkillsCourseList,
  CourseCreate as SkillsCourseCreate,
  CourseEdit as SkillsCourseEdit,
} from './components/skills/Course';
import {
  VideoList as SkillsVideoList,
  VideoCreate as SkillsVideoCreate,
  VideoEdit as SkillsVideoEdit,
} from './components/skills/Video';
import {
  PurchaseOrderList,
  PurchaseOrderCreate,
} from './components/skills/PurchaseOrder';
import {
  PurchaseOrderListForLingoJourney,
  PurchaseOrderCreateForLingoJourney,
} from './components/lingo/PurchaseOrderForLingoJourney';
import i18nProvider from './utils/i18n/i18nProvider';
import {
  LiveStreamingScheduleList,
  LiveStreamingScheduleEdit,
  LiveStreamingScheduleCreate,
} from './components/LiveStreamingSchedule';
import { CommentList } from './components/Comment';
import { CountryCreate, CountryEdit, CountryList } from './components/Country';
import { LanguageCreate, LanguageEdit, LanguageList } from './components/Language';
import { TaggingCreate } from './components/Tagging';
import { VideoTypeList, VideoTypeEdit, VideoTypeCreate } from './components/VideoType';
import { LanguageLevelList, LanguageLevelEdit, LanguageLevelCreate } from './components/LanguageLevel';
import {
  QuickInformationList,
  QuickInformationEdit,
  QuickInformationCreate,
} from './components/QuickInformation';
import {
  MajorFieldList,
  MajorFieldCreate,
  MajorFieldEdit,
} from './components/MajorField';
import {
  ReviewList,
  ReviewEdit,
} from './components/Review';
import {
  AverageReviewList,
} from './components/AverageReview';

import { LingoJourneyList, LingoJourneyCreate, LingoJourneyEdit } from './components/lingo/LingoJourney';
import { LingoJourneyStepList, LingoJourneyStepCreate, LingoJourneyStepEdit } from './components/lingo/LingoJourneyStep';
import {
  LanguageLevelDiscountCreate,
  LanguageLevelDiscountEdit,
  LanguageLevelDiscountList
} from "./components/lingo/LingoLanguageLevelDiscount";
import {
  LanguageLevelCouponList,
  LanguageLevelCouponCreate,
} from "./components/lingo/LingoLanguageLevelCoupon";
import { LingoQuizList, LingoQuizEdit, LingoQuizCreate } from './components/lingo/LingoQuizzes';
import {
  LingoQuestionList,
  LingoQuestionEdit,
  LingoQuestionCreate,
} from './components/lingo/LingoQuestions';
import { LingoQuizSessionList } from './components/lingo/LingoQuizSession';
import { LTQuizTypeList, LTQuizTypeEdit, LTQuizTypeCreate } from './components/lingo/LTQuizType';
import { LTQuizList, LTQuizEdit, LTQuizCreate } from './components/lingo/LTQuizzes';
import { LTQuestionList, LTQuestionEdit, LTQuestionCreate } from './components/lingo/LTQuestions';
import { LTQuizBundleList, LTQuizBundleEdit, LTQuizBundleCreate } from './components/lingo/LTQuizBundle';
import { LTQuizSessionList } from './components/lingo/LTQuizSession';
import {
  PurchaseOrderCreateForQuizBundle,
  PurchaseOrderListForQuizBundle
} from "./components/lingo/PurchaseOrderForQuizBundle";
import {
  EventList,
  EventEdit,
  EventCreate,
} from './components/Event';
import {
  PurchaseOrderListForEvent,
  PurchaseOrderCreateForEvent,
} from './components/PurchaseOrderForEvent'
import {
  ParticipantList,
  ParticipantCreate,
  ParticipantEdit,
} from './components/Participant';

import {
  TrainingList,
  TrainingCreate,
  TrainingEdit
} from './components/Training';

function App() {
  return (
    <Admin layout={Layout} dataProvider={dtProvider} authProvider={authProvider}  i18nProvider={i18nProvider}>
      {permissions => [
        <Resource name="world" icon={ArrowForwardIosIcon} options={{ label: 'World', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
          name="countries"
          options={{ label: 'Улс**', menuParent: 'world' }}
          list={CountryList}
          edit={CountryEdit}
          create={CountryCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
          name="languages"
          options={{ label: 'Хэл**', menuParent: 'world' }}
          list={LanguageList}
          edit={LanguageEdit}
          create={LanguageCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
          name="videoTypes"
          options={{ label: 'Видеоны төрөл**', menuParent: 'world' }}
          list={VideoTypeList}
          edit={VideoTypeEdit}
          create={VideoTypeCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
          name="languageLevels"
          options={{ label: 'Хэлний түвшин**', menuParent: 'world' }}
          list={LanguageLevelList}
          edit={LanguageLevelEdit}
          create={LanguageLevelCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
            name="majorFields"
            options={{ label: 'Чиглэл**', menuParent: 'world' }}
            list={MajorFieldList}
            edit={MajorFieldEdit}
            create={MajorFieldCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
          name="taggings"
          create={TaggingCreate}
        />,
        <Resource
          name="courses"
          options={{ label: 'Хичээл', menuParent: 'world' }}
          list={CourseList}
          edit={CourseEdit}
          create={CourseCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="coursesSections"
            options={{ label: 'Анги', menuParent: 'world' }}
            list={CourseSectionList}
            edit={CourseSectionEdit}
            create={CourseSectionCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="contentProviders"
            options={{ label: 'ECC', menuParent: 'world' }}
            list={ContentProviderList}
            edit={ContentProviderEdit}
            create={ContentProviderCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="tags"
            options={{ label: 'Tags', menuParent: 'world' }}
            list={TagList}
            edit={TagEdit}
            create={TagCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.DATABASE_ADMIN)) &&
          <Resource
            name="scholarships"
            options={{ label: 'Тэтгэлэг', menuParent: 'world' }}
            list={ScholarshipList}
            edit={ScholarshipEdit}
            create={ScholarshipCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.DATABASE_ADMIN)) &&
          <Resource
            name="universities"
            options={{ label: 'Их сургууль', menuParent: 'world' }}
            list={UniversityList}
            edit={UniversityEdit}
            create={UniversityCreate}
          />,
        !permissions.includes(ROLES.ADMIN_MENTOR_ROLE) &&
          <Resource
            name="banners"
            options={{ label: 'Тун удахгүй орох зүйлс', menuParent: 'world' }}
            list={ExploreBannerList}
            edit={ExploreBannerEdit}
            create={ExploreBannerCreate}
          />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
          <Resource
            name="questionExplanations"
            options={{ label: 'Тайлбар', menuParent: 'world' }}
            list={ExplanationList}
            edit={ExplanationEdit}
            create={ExplanationCreate}
          />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
          <Resource
            name="quickInformations"
            options={{ label: 'Товч мэдээлэл', menuParent: 'world' }}
            list={QuickInformationList}
            edit={QuickInformationEdit}
            create={QuickInformationCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.DATABASE_ADMIN)) &&
          <Resource name="mentor" icon={ArrowForwardIosIcon} options={{ label: 'Ментор', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.DATABASE_ADMIN)) &&
          <Resource
            name="mentors"
            options={{ label: 'Ментор', menuParent: 'mentor' }}
            list={MentorList}
            edit={MentorEdit}
            create={MentorCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="askingMentors"
            options={{ label: 'Менторын асуулт, хариулт', menuParent: 'mentor' }}
            list={AskingMentorList}
            edit={AskingMentorEdit}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="mentoringTimeRequests"
            options={{ label: 'Менторын цаг товлолт', menuParent: 'mentor' }}
            list={MentoringTimeRequestList}
            edit={MentoringTimeRequestEdit}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
        <Resource
          name="trainings"
          options={{ label: 'Сургалт', menuParent: 'mentor' }}
          list={TrainingList}
          edit={TrainingEdit}
          create={TrainingCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="timeslots"
            options={{ label: 'Менторын боломжит цагууд', menuParent: 'mentor' }}
            list={TimeslotList}
            edit={TimeslotEdit}
            create={TimeslotCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN)) &&
          <Resource name="test" icon={ArrowForwardIosIcon} options={{ label: 'Тест', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN)) &&
          <Resource
            name="quizBundles"
            options={{ label: 'Тестийн бүлэг', menuParent: 'test' }}
            list={QuizBunleList}
            edit={QuizBundleEdit}
            create={QuizBundleCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN) ) &&
          <Resource
            name="quizzes"
            options={{ label: 'Тестийн шалгалт', menuParent: 'test' }}
            list={QuizList}
            edit={QuizEdit}
            create={QuizCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN)) &&
          <Resource
            name="questions"
            options={{ label: 'Тестийн асуулт', menuParent: 'test' }}
            list={QuestionList}
            edit={QuestionEdit}
            create={QuestionCreate}
          />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
          <Resource
            name="quizSessions"
            options={{ label: 'Тестийн түүх', menuParent: 'test' }}
            list={QuizSessionList}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource name="gaming" icon={ArrowForwardIosIcon} options={{ label: 'Тоглоом', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name="gamingBalances"
            options={{ label: 'Тоглогчийн данс', menuParent: 'gaming' }}
            list={GamingBalanceList}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name="gamingCategories"
            options={{ label: 'Тоглоомын төрөл', menuParent: 'gaming' }}
            list={GamingCategoryList}
            edit={GamingCategoryEdit}
            create={GamingCategoryCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name="gamingLevelInstructions"
            options={{ label: 'Тоглоомын түвшний заавар', menuParent: 'gaming' }}
            list={GameLevelInstructionList}
            create={GameLevelInstructionCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name='gamingChallenges'
            options={{ label: 'Тоглоомын тэмцээн', menuParent: 'gaming' }}
            list={GameChallengeList}
            create={GameChallengeCreate}
            edit={GameChallengeEdit}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name="gamingQuizes"
            options={{ label: 'Тоглоомын quiz', menuParent: 'gaming' }}
            list={GameQuizList}
            edit={GameQuizEdit}
            create={GameQuizCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name="gameTags"
            options={{ label: 'Тоглоомын Tag', menuParent: 'gaming' }}
            list={GameTagList}
            edit={GameTagEdit}
            create={GameTagCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
          <Resource
            name="TGQuestions"
            options={{ label: 'Тоглоомын Асуулт', menuParent: 'gaming' }}
            list={TGQuestionList}
            edit={TGQuestionEdit}
            create={TGQuestionCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
        <Resource
          name="classBanners"
          options={{ label: 'banners', menuParent: 'gaming' }}
          list={ClassBannerList}
          edit={ClassBannerEdit}
          create={ClassBannerCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.GAME_ADMIN)) &&
        <Resource
          name="classChallengeBanners"
          options={{ label: 'challenge popup banner', menuParent: 'gaming' }}
          list={ClassChallengeBannerList}
          edit={ClassChallengeBannerEdit}
          create={ClassChallengeBannerCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource name="user" icon={ArrowForwardIosIcon} options={{ label: 'Хэрэглэгч', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="users"
            options={{ label: 'Хэрэглэгч', menuParent: 'user' }}
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
          />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
          <Resource
            name="Allusers"
            options={{ label: 'Хэрэглэгчийн тоо', menuParent: 'user' }}
            list={UserCount}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="referrals"
            options={{ label: 'Найзын урилга', menuParent: 'user' }}
            list={ReferralsList}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="1k-promo/codes"
            options={{ label: 'Промо код', menuParent: 'user' }}
            list={PromoCodeList}
          />,
        (permissions.includes(ROLES.SALES_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="notifications"
            options={{ label: 'SMS Илгээх', menuParent: 'user' }}
            list={NotificationList}
            create={NotificationCreate}
          />,
        permissions.includes('SUPER_ADMIN') &&
          <Resource
            name="adminUsers"
            options={{ label: 'Админ хэрэглэгч', menuParent: 'user' }}
            list={AdminUserList}
            edit={AdminUserEdit}
            create={AdminUserCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource name="sales" icon={ArrowForwardIosIcon} options={{ label: 'Sales', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="userSubscriptions"
            options={{ label: 'Хэрэглэгчийн төлбөр', "menuParent":"sales" }}
            list={UserSubscriptionList}
            edit={UserSubscriptionEdit}
            create={UserSubscriptionCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="subscriptionPlans"
            options={{ label: 'Төлбөрийн төрөл', "menuParent":"sales" }}
            list={SubscriptionPlanList}
            edit={SubscriptionPlanEdit}
            create={SubscriptionPlanCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="payments"
            options={{ label: 'Төлбөрийн гүйлгээ', "menuParent":"sales" }}
            list={PaymentList}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="bankTransactions"
            options={{ label: 'Банкны гүйлгээ', "menuParent":"sales" }}
            list={TransactionList}
            create={TransactionCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="paymentLogs"
            options={{ label: 'Төлбөрийн төлөлтийн түүх', "menuParent":"sales" }}
            list={PaymentLogList}
          />,
        (permissions.includes(ROLES.SALES_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="coupons"
            options={{ label: 'Купон', "menuParent":"sales" }}
            list={CouponList}
            edit={CouponEdit}
            create={CouponCreate}
          />,
        (permissions.includes(ROLES.SALES_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="bulk/coupons"
            options={{ label: 'Олон купон үүсгэх', "menuParent":"sales" }}
            create={BulkCouponCreate}
          />,
        permissions.includes('SUPER_ADMIN') &&
          <Resource name="codeRelated" icon={ArrowForwardIosIcon} options={{label: 'Тохиргоо', isMenuParent:true}} />,
        permissions.includes('SUPER_ADMIN') &&
          <Resource
            name="featureTargets"
            options={{ label: 'Pilot features', "menuParent":"codeRelated" }}
            icon={FiberNewIcon}
            list={FeatureTargetList}
            create={FeatureTargetCreate}
          />,
        permissions.includes('SUPER_ADMIN') &&
          <Resource
            name="deploymentConfigs"
            icon={SettingsIcon}
            options={{ label: 'Deployment Configs',"menuParent":"codeRelated" }}
            list={DeploymentConfigList}
            edit={DeploymentConfigEdit}
            create={DeploymentConfigCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource name="tomyo-skills" icon={ArrowForwardIosIcon} options={{ label: 'Skills', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="skills/categories"
            options={{ label: 'Ангилал', menuParent: 'tomyo-skills' }}
            list={SkillsCategoryList}
            edit={SkillsCategoryEdit}
            create={SkillsCategoryCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="skills/creators"
            options={{ label: 'Creator', menuParent: 'tomyo-skills' }}
            list={SkillsCreatorList}
            edit={SkillsCreatorEdit}
            create={SkillsCreatorCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="skills/courses"
            options={{ label: 'Хичээл', menuParent: 'tomyo-skills' }}
            list={SkillsCourseList}
            edit={SkillsCourseEdit}
            create={SkillsCourseCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="skills/videos"
            options={{ label: 'Видео', menuParent: 'tomyo-skills' }}
            list={SkillsVideoList}
            edit={SkillsVideoEdit}
            create={SkillsVideoCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="skills/purchaseOrders"
            options={{ label: 'Захиалга', menuParent: 'tomyo-skills' }}
            list={PurchaseOrderList}
            create={PurchaseOrderCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource name="ielts" icon={ArrowForwardIosIcon} options={{ label: 'IELTS', isMenuParent: true }} />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
            name="lt/quizTypes"
            options={{ label: 'Төрөл', menuParent: 'ielts' }}
            list={LTQuizTypeList}
            edit={LTQuizTypeEdit}
            create={LTQuizTypeCreate}
        />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
            name="lt/quizBundles"
            options={{ label: 'Багц', menuParent: 'ielts' }}
            list={LTQuizBundleList}
            edit={LTQuizBundleEdit}
            create={LTQuizBundleCreate}
        />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
            name="lt/quizzes"
            options={{ label: 'Тест', menuParent: 'ielts' }}
            list={LTQuizList}
            edit={LTQuizEdit}
            create={LTQuizCreate}
        />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
            name="lt/questions"
            options={{ label: 'Асуулт', menuParent: 'ielts' }}
            list={LTQuestionList}
            edit={LTQuestionEdit}
            create={LTQuestionCreate}
        />,

        permissions.includes(ROLES.SUPER_ADMIN) &&
        <Resource
            name="lt/quizSessions"
            options={{ label: 'Тестийн түүх', menuParent: 'ielts' }}
            list={LTQuizSessionList}
        />,

        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
        <Resource
            name="purchaseOrders/lingo/lt-quiz-bundles"
            options={{ label: 'Захиалга', menuParent: 'ielts' }}
            list={PurchaseOrderListForQuizBundle}
            create={PurchaseOrderCreateForQuizBundle}
        />,

        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource name="journey" icon={ArrowForwardIosIcon} options={{ label: 'Багц', isMenuParent: true }} />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
              name="lingo/journeys"
              options={{ label: 'Багцууд', menuParent: 'journey' }}
              list={LingoJourneyList}
              edit={LingoJourneyEdit}
              create={LingoJourneyCreate}
          />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
              name="lingo/journeysteps"
              options={{ label: 'Багцуудын алхам', menuParent: 'journey' }}
              list={LingoJourneyStepList}
              edit={LingoJourneyStepEdit}
              create={LingoJourneyStepCreate}
          />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="purchaseOrders/lingo/journeys"
            options={{ label: 'Захиалга', menuParent: 'journey' }}
            list={PurchaseOrderListForLingoJourney}
            create={PurchaseOrderCreateForLingoJourney}
          />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="comments"
            options={{ label: 'Коммент', menuParent: 'journey' }}
            list={CommentList}
          />,
        (permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="liveStreamingSchedules"
            options={{ label: 'Live Streaming хуваарь', menuParent: 'journey' }}
            list={LiveStreamingScheduleList}
            edit={LiveStreamingScheduleEdit}
            create={LiveStreamingScheduleCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="languageLevelDiscounts"
            options={{ label: 'Хямдралын төрөл', menuParent: 'journey' }}
            list={LanguageLevelDiscountList}
            edit={LanguageLevelDiscountEdit}
            create={LanguageLevelDiscountCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.PRODUCTION_ADMIN)) &&
          <Resource
            name="languageLevelCoupons"
            options={{ label: 'Хямдралын купон', menuParent: 'journey' }}
            list={LanguageLevelCouponList}
            create={LanguageLevelCouponCreate}
          />,
          <Resource
          name="event"
          icon={ArrowForwardIosIcon}
          options={{ label: 'Эвент', isMenuParent: true }}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)|| permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="events"
            options={{
              label: 'Эвент',
              menuParent: 'event'
            }}
            list={EventList}
            edit={EventEdit}
            create={EventCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)|| permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="purchaseOrders/events"
            options={{
              label: 'Захиалга',
              menuParent: 'event'
            }}
            list={PurchaseOrderListForEvent}
            create={PurchaseOrderCreateForEvent}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)|| permissions.includes(ROLES.MENTOR_ADMIN)) &&
          <Resource
            name="eventsParticipants"
            options={{
              label: 'Оролцогч',
              menuParent: 'event'
            }}
            list={ParticipantList}
            create={ParticipantCreate}
            edit={ParticipantEdit}
          />,
      (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN)) &&
        <Resource name="lingo-test" icon={ArrowForwardIosIcon} options={{ label: 'Багцын тестүүд', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="lingo/quizzes"
            options={{ label: 'Тестийн шалгалт', menuParent: 'lingo-test' }}
            list={LingoQuizList}
            edit={LingoQuizEdit}
            create={LingoQuizCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="lingo/questions"
            options={{ label: 'Тестийн асуулт', menuParent: 'lingo-test' }}
            list={LingoQuestionList}
            edit={LingoQuestionEdit}
            create={LingoQuestionCreate}
          />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
          <Resource
            name="lingo/quizSessions"
            options={{ label: 'Тестийн түүх', menuParent: 'lingo-test' }}
            list={LingoQuizSessionList}
          />,
      permissions.includes(ROLES.ADMIN_MENTOR_ROLE) &&
        <Resource
          name="mentors/timeSlots"
          options={{ label: 'Боломжит цаг' }}
          list={MentorTimeSlotList}
          create={MentorTimeSlotCreate}
        />,
      permissions.includes(ROLES.ADMIN_MENTOR_ROLE) &&
        <Resource
          name="mentors/askingMentors"
          options={{ label: 'Асуулт, хариулт' }}
          list={MentorsQuestionsList}
          edit={MentorsQuestionsEdit}
        />,
      permissions.includes('SUPER_ADMIN') &&
        <Resource name="review" icon={ArrowForwardIosIcon} options={{label: 'Сэтгэгдэл', isMenuParent:true}} />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
        <Resource
            name="reviews"
            options={{ label: 'Сэтгэгдэл', menuParent: 'review' }}
            list={ReviewList}
            edit={ReviewEdit}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN)) &&
        <Resource
            name="averageReviews"
            options={{ label: 'Дундаж үнэлгээ', menuParent: 'review' }}
            list={AverageReviewList}
        />,

      ]}
    </Admin>
  );
}

export default App;
