import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  FileField,
  FileInput,
  TextField,
  TextInput,
  EditButton,
  ArrayInput,
  SelectInput,
  FunctionField,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceField,
  AutocompleteInput,
  BooleanInput,
  Filter,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { COURSE_PROVIDER_CONTENT_TYPES } from '../constants/Course';
import { APP_TYPES } from "../constants";

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Сэдэв" source="topic" alwaysOn />
    <ReferenceInput label="Багшын нэр" source="authorId" reference="contentProviders" alwaysOn>
      <AutocompleteInput
        optionText={item => (item.name ? `${item.id} - ${item.name}` : '')}
        shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
        options={{ suggestionsContainerProps: { disablePortal: true } }}
      />
    </ReferenceInput>
    <SelectInput emptyText="Бүгд" label="App type" source="appType" choices={APP_TYPES} alwaysOn  />
  </Filter>
);

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const CourseList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Сэдэв" source="topic" />
      <TextField label="Хугацаа" source="totalDuration" />
      <ReferenceField source="authorId" reference="contentProviders">
        <TextField label="Зохиогч" source="name" />
      </ReferenceField>
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <TextField label="tags" source="tags" />
      <EditButton />
    </Datagrid>
  </List>
);

const CourseTitle = ({ record }) => {
  const { id } = record;
  return <span>Courses {record ? `"${id}"` : ''}</span>;
};

export const CourseEdit = props => (
  <Edit title={<CourseTitle />} {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput disabled source="id" />
      <SelectInput
        label="Контентын түвшин"
        source="providerContentType"
        choices={COURSE_PROVIDER_CONTENT_TYPES}
      />
      <TextInput fullWidth label="Сэдэв" source="topic" />
      <SelectArrayInput label="App" source="appType" choices={APP_TYPES} validate={[required()]} />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput label="Видео контентын төрөл" source="tagging.videoType" reference="videoTypes" validate={[required()]}>
        <SelectInput optionText="type" />
      </ReferenceInput>
      <ReferenceArrayInput  label="Хичээлийн түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="tags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <ReferenceInput label="Зохиогч" source="authorId" reference="contentProviders" perPage={100} >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Хугацаа" source="totalDuration" />
      <ImageField source="thumbnailImage" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
      <ArrayInput
        fullWidth
        label="Юу юунаас бүрдэж байгаа вэ?"
        source="includes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" source="value" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Хүлээж буй зүйлс" source="outcomes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" source="topic" />
        </SimpleFormIterator>
      </ArrayInput>
      <RichTextInput label="Танилцуулга" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <ArrayInput label="Шаардлагаууд" source="requirements">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" source="topic" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const CourseCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} />
      <TextInput fullWidth label="Сэдэв" source="topic" />
      <SelectInput
        label="Контентын түвшин"
        source="providerContentType"
        choices={COURSE_PROVIDER_CONTENT_TYPES}
      />
      <SelectArrayInput label="App" source="appType" choices={APP_TYPES} validate={[required()]} />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput label="Видео контентын төрөл" source="tagging.videoType" reference="videoTypes" validate={[required()]}>
        <SelectInput optionText="type" />
      </ReferenceInput>
      <ReferenceArrayInput  label="Хичээлийн түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="tags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <ReferenceInput label="Зохиогч" source="authorId" reference="contentProviders" perPage={100} >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Хугацаа" source="totalDuration" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
      <ArrayInput
        fullWidth
        label="Юу юунаас бүрдэж байгаа вэ?"
        source="includes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" source="value" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Хүлээж буй зүйлс" source="outcomes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" source="topic" />
        </SimpleFormIterator>
      </ArrayInput>
      <RichTextInput label="Танилцуулга" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <ArrayInput fullWidth label="Шаардлагаууд" source="requirements">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" source="topic" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
