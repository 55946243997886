import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  DateTimeInput,
  ExportButton,
  Filter,
  AutocompleteInput,
  NumberField,
  ReferenceField,
  SelectInput,
  DateField,
  BooleanField,
  ReferenceInput,
  CreateButton,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
} from 'react-admin';
import dataProvider from "../../utils/dataProvider";

const CodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="code" alwaysOn />
  </Filter>
);

const LanguageLevelCouponActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const ListFilterInput = props => {
  const {discountPlanId} = props;

  const [discountPlan, setDiscountPlan] = useState();
  useEffect(() => {
    async function fetchData(){
      if (discountPlanId) {
        const discountItem = await dataProvider.getOne(`languageLevelDiscounts`, {
          id: discountPlanId,
        });
        setDiscountPlan(discountItem.data)
      }
    }
    fetchData();
  }, [discountPlanId])
  return (
    <React.Fragment>
      <TextInput label="Дүн" initialValue={discountPlan?.sale} value={discountPlan?.sale || 0} source="amount" disabled />
    </React.Fragment>
  );
}

export const LanguageLevelCouponList = props => (
  <List {...props} filters={<CodeFilter/>} actions={<LanguageLevelCouponActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Код" source="code" />
      <TextField label="Код" source="description" />
      <NumberField label="Дүн" source="amount" /> 
      <ReferenceField source="userId" reference="users">
        <TextField label="Нэр" source="firstName" />
      </ReferenceField>
      <BooleanField label="Ашигласан эсэх" source="isUsed" />
      <DateField label="Эхлэх огноо" source="startsAt" />
      <DateField label="Дуусах огноо" source="expiresAt" />
    </Datagrid>
  </List>
);

export const LanguageLevelCouponCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
      <TextInput label="Код" source="code" validate={[required(), minLength(6), maxLength(8)]} helperText="6 - 8 урттай үсэг тоо холилдсон том үсгээр" />
        <TextInput label="Зорилго" source="description" />
        <ReferenceInput label="Хямдралын төрөл" source="discountPlanId" reference="languageLevelDiscounts" validate={[required()]}>
          <SelectInput optionText="title" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <ListFilterInput discountPlanId={formData && formData?.discountPlanId} {...rest} />
            )
          }}
        </FormDataConsumer>
        <ReferenceInput source="userId" reference="users">
          <AutocompleteInput
            optionText={item => (item.email ? item.email : '')}
            shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
            options={{ suggestionsContainerProps: { disablePortal: true } }}
          />
        </ReferenceInput>
        <DateTimeInput label="Эхлэх огноо" source="startsAt" validate={required()} />
        <DateTimeInput label="Дуусах огноо" source="expiresAt" validate={required()} />  
      </SimpleForm>
    </Create>
  )
};
