export const QuestionTypes = [
  { id: 'SINGLE', name: 'SINGLE' },
  { id: 'MULTI', name: 'MULTI' },
  { id: 'MATCHING', name: 'MATCHING' },
  { id: 'PRONUNCIATION', name: 'PRONUNCIATION' },
  { id: 'WRITE', name: 'WRITE' },
  { id: 'BOOLEAN', name: 'TRUE AND FALSE' },
];

export const LTQuestionTypes = [
  { id: 'SINGLE', name: 'SINGLE' },
  { id: 'MULTI', name: 'MULTI' },
  { id: 'SELECT', name: 'SELECT' },
  { id: 'WRITE', name: 'WRITE' },
  { id: 'AUDIO', name: 'AUDIO' },
];
