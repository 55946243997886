import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  NumberInput,
  FileInput,
  FileField,
  ImageField,
  BooleanInput,
  ReferenceInput,
  Filter,
  CreateButton,
  ExportButton,
  ReferenceField,
  required
} from 'react-admin';

const LingoJourneyStepFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Багцын ID" source="journeyId" alwaysOn /> 
  </Filter>
);

const LingoJourneyStepActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const LingoJourneyStepList = props => (
  <List {...props} filters={<LingoJourneyStepFilter/>} actions={<LingoJourneyStepActions props={props} />}>
    <Datagrid optimized>
      <TextField label="id" source="id" />
      <TextField label="Ангийн ID" source="courseSectionId" />
      <ReferenceField label="Хичээлийн ангийн нэр" source="courseSectionId" reference="coursesSections">
        <TextField label="Нэр" source="title" />
      </ReferenceField>
      <TextField label="Багцын ID" source="journeyId" />
      <ReferenceField label="Багцын нэр" source="journeyId" reference="lingo/journeys">
        <TextField label="title" source="title" />
      </ReferenceField>
      <TextField label="Дараалал" source="order" />
      <EditButton />
    </Datagrid>
  </List>
);

const Title = ({ record }) => {
  const { title } = record;
  return <span>Journey Step {record ? `"${title}"` : ''}</span>;
};

export const LingoJourneyStepEdit = props => {
  const optionPackageRenderer = choice => `${choice.id} - ${choice.title}`;
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <CloneButton />
        <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
        <TextInput disabled source="id" />
        <TextInput fullWidth label="title" source="title" helperText="Хичээлийн ангийн нэрийг солих хэрэгтэй бол солигдсон байдлаар оруулна. Үгүй бол хичээлийн ангийн нэрийг хуулаад тавина. App дээр энэ нэрээр харагдана." validate={[required()]} />
        <ReferenceInput fullWidth source="journeyId" reference="lingo/journeys" perPage={200} validate={[required()]} sort={{ field: 'id', order: 'DESC' }}>
          <SelectInput optionText={optionPackageRenderer} optionValue="id" />  
        </ReferenceInput>
        <NumberInput label="Ангийн ID" source="courseSectionId" />
        <NumberInput label="order" source="order" />
        <TextField label="Шаардлагатай бол бөглөх хэсгүүд (Доорх бүгд)" source="" />
        <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
        <ImageField source="thumbnail" title="Зураг" />
        <FileInput source="file" label="Зураг" accept="image/*">
          <FileField source="image" title="image" />
        </FileInput>
        <TextInput fullWidth label="homeworkLink" source="homeworkLink" />
        <FileInput source="homeworkFile" label="Гэрийн даалгавар" accept="application/pdf" >
          <FileField source="src" title="title" />
        </FileInput>
        <NumberInput label="Quiz ID" source="contentId" />
      </SimpleForm>
    </Edit>
  )
}

export const LingoJourneyStepCreate = props => {
  const optionPackageRenderer = choice => `${choice.id} - ${choice.title}`;
  return (
    <Create {...props}>
      <SimpleForm>
        <CloneButton />
        <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
        <TextInput fullWidth label="title" source="title" helperText="Хичээлийн ангийн нэрийг солих хэрэгтэй бол солигдсон байдлаар оруулна. Үгүй бол хичээлийн ангийн нэрийг хуулаад тавина. App дээр энэ нэрээр харагдана." validate={[required()]} />
        <ReferenceInput fullWidth source="journeyId" reference="lingo/journeys" perPage={200} validate={[required()]} sort={{ field: 'id', order: 'DESC' }}>
          <SelectInput optionText={optionPackageRenderer} optionValue="id" />  
        </ReferenceInput>
        <NumberInput label="Ангийн ID" source="courseSectionId" />
        <NumberInput label="order" source="order" />
        <TextField label="Шаардлагатай бол бөглөх хэсгүүд (Доорх бүгд)" source="" />
        <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
        <NumberInput label="Quiz ID" source="contentId" />
        <ImageField source="thumbnail" title="Зураг" />
        <FileInput fullWidth source="file" label="Зураг" accept="image/*">
          <FileField source="image" title="image" />
        </FileInput>
        <FileInput source="homeworkFile" label="Гэрийн даалгавар" accept="application/pdf" >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}
