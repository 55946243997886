import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ExportButton,
  DateField,
  ReferenceField,
} from 'react-admin';

const TrxIdFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Гүйлгээний ID" source="invoiceNo" alwaysOn />
  </Filter>
);

const PaymentActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};

export const PaymentList = props => (
  <List {...props} filters={<TrxIdFilter/>} actions={<PaymentActions props={props} />}>
    <Datagrid optimized>
      <TextField label="Гүйлгээний ID" source="invoiceNo" />
      <TextField label="Дүн" source="amount" />
      <TextField label="Төлөв" source="status" />
      <TextField label="Алдааны тайлбар" source="errorDesc" />
      <ReferenceField label="Хэрэглэгч"  source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField label="Бүтээгдэхүүн" source="productId" />
      <TextField label="Бүтээгдэхүүны төрөл" source="productType" />
      <TextField label="Provider" source="provider" />
      <DateField showTime label="Үүссэн" source="createdAt" />
      <ReferenceField label="Купон"  source="couponId" reference="coupons">
        <TextField source="code" />
      </ReferenceField>
      <ReferenceField label="Багцын Купон" source="packageCouponId" reference="languageLevelCoupons">
        <TextField source="code" />
      </ReferenceField>
    </Datagrid>
  </List>
);
