import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  Filter,
  FunctionField,
  SelectInput,
  ReferenceField,
} from 'react-admin';
const getMentorEmail = mentor => `${mentor?.user?.email}`;
const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}
const TextFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Mentor email" source="mentorId" reference="mentors" alwaysOn>
      <AutocompleteInput
          optionText={getMentorEmail}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
      />
    </ReferenceInput>
    <TextInput label="Title" source="title" alwaysOn/>
  </Filter>
);

export const TrainingList = props => (
  <List {...props} filters={<TextFilter/>}>
    <Datagrid optimized>
      <TextField label="Id" source="id" />
      <ReferenceField label="Mentor" source="mentorId" reference="mentors">
        <FunctionField label="Статус" render={record => getMentorEmail(record)} />
      </ReferenceField>
      <TextField label="Title" source="title"/>
      <TextField label="ShortDesc" source="shortDescription"/>
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

export const TrainingEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput label="Mentor email" source="mentorId" reference="mentors" fullWidth>
        <AutocompleteInput
            optionText={getMentorEmail}
            shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
            options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput label="Title" source="title" fullWidth/>
      <TextInput multiline label="Short description" source="shortDescription" fullWidth/>
      <TextInput multiline label="Description" source="description" fullWidth/>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const TrainingCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Mentor email" source="mentorId" reference="mentors" fullWidth>
        <AutocompleteInput
            optionText={getMentorEmail}
            shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
            options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput label="Title" source="title" fullWidth/>
      <TextInput multiline label="Short description" source="shortDescription" fullWidth/>
      <TextInput multiline label="Description" source="description" fullWidth/>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Create>
)
