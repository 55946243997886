import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  NumberInput,
  BooleanInput,
  Filter,
  CreateButton,
  SelectArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import dataProvider from "../../utils/dataProvider";

const LanguageLevelDiscountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="title" source="title" alwaysOn /> 
  </Filter>
);

const LanguageLevelDiscountActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const LanguageLevelDiscountList = props => (
  <List {...props} filters={<LanguageLevelDiscountFilter/>} actions={<LanguageLevelDiscountActions props={props} />}>
    <Datagrid optimized>
      <TextField label="id" source="id" />
      <TextField label="language" source="languageCode" />
      <TextField label="languageLevel" source="languageLevel" />
      <TextField label="title" source="title" />
      <TextField label="isActive" source="isActive" />
      <TextField label="sale" source="sale" />
      <EditButton />
    </Datagrid>
  </List>
);

const Title = ({ record }) => {
  const { title } = record;
  return <span>Journey {record ? `"${title}"` : ''}</span>;
};

export const LanguageLevelDiscountEdit = props => {
  const [languageLevels, setLanguageLevels] = useState([]);
  const [languages, setLanguages] = useState();

  useEffect(() => {
    async function fetchData(){
      // set languages
      const langData = await dataProvider.getList('languages', {
        "pagination": {
          "page": 1,
          "perPage": 500
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {}
      });
      const languages = [];
      for (let item of langData.data) {
        languages.push({
          id: item.code,
          name: item.name
        })
      }
      setLanguages(languages);

      // set language levels
      const levelData = await dataProvider.getList('languageLevels', {
        "pagination": {
          "page": 1,
          "perPage": 500
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {}
      });
      const levels = [];
      for (let item of levelData.data) {
        levels.push({
          id: item.level,
          name: item.level
        })
      }
      setLanguageLevels(levels);
    }
    fetchData();
  }, [])

  return (
      <Edit title={<Title />} {...props}>
        <SimpleForm>
          <CloneButton />
          <TextInput disabled source="id" />
          <TextInput fullWidth label="title" source="title" />
          <SelectInput optionText="name" source="languageCode" choices={languages}  />
          <SelectArrayInput optionText="name" source="languageLevel" choices={languageLevels}  />
          <BooleanInput label="isActive" source="isActive" initialValue={false} />
          <RichTextInput label="additionalDescription" source="additionalDescription" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
          <NumberInput label="sale" source="sale" />
        </SimpleForm>
      </Edit>
  )
};

export const LanguageLevelDiscountCreate = props => {
  const [languageLevels, setLanguageLevels] = useState();
  const [languages, setLanguages] = useState();

  useEffect(() => {
    async function fetchData(){
      // set languages
      const langData = await dataProvider.getList('languages', {
        "pagination": {
          "page": 1,
          "perPage": 500
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {}
      });
      const languages = [];
      for (let item of langData.data) {
        languages.push({
          id: item.code,
          name: item.name
        })
      }
      setLanguages(languages);

      // set language levels
      const levelData = await dataProvider.getList('languageLevels', {
        "pagination": {
          "page": 1,
          "perPage": 500
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {}
      });
      const levels = [];
      for (let item of levelData.data) {
        levels.push({
          id: item.level,
          name: item.level
        })
      }
      setLanguageLevels(levels);
    }
    fetchData();
  }, [])

  return (
      <Create {...props}>
        <SimpleForm>
          <CloneButton />
          <TextInput fullWidth label="title" source="title" />
          <SelectInput optionText="name" source="languageCode" choices={languages}  />
          <SelectArrayInput optionText="name" source="languageLevel" choices={languageLevels}  />
          <BooleanInput label="isActive" source="isActive" initialValue={false} />
          <RichTextInput label="additionalDescription" source="additionalDescription" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
          <NumberInput label="sale" source="sale" />
        </SimpleForm>
      </Create>
  )
}
