import React from 'react';
import {
  required,
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  Filter,
  TextInput,
  FunctionField,
} from 'react-admin';
import RejectPurchaseOrderBtn from '../skills/RejectPurchaseOrderBtn';

const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User Id" source="userId" />
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
  </Filter>
);

const renderRejectButton = (status, id) => {
  if (status !== 'APPROVED') {
    return '';
  }

  return <RejectPurchaseOrderBtn id={id} />;
}

export const PurchaseOrderListForLingoJourney = props => (
  <List title="Хөтөлбөрийн захиалгууд" {...props} filters={<OrderFilter/>}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField label="Хөтөлбөр" source="items.0.productId" reference="lingo/journeys">
        <TextField label="Нэр" source="title" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" label="Имэйл">
        <TextField source="email" />
      </ReferenceField>
      <TextField label="Төлбөр" source="items.0.amount" />
      <TextField label="Статус" source="status" />
      <FunctionField label="" render={record => renderRejectButton(record.status, record.id)} />
      <EditButton />
    </Datagrid>
  </List>
);

export const PurchaseOrderCreateForLingoJourney = props => (
  <Create title="Хөтөлбөрийн захиалга үүсгэх" {...props}>
    <SimpleForm>
      <ReferenceInput label="Хэрэглэгч" source="userId" reference="users" validate={[required()]}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput
        label="Journey"
        source="journeyId"
        filterToQuery={q => ({ title: q })}
        reference="lingo/journeys"
        validate={[required()]}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
