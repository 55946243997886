import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  SelectField,
  TextInput,
  NumberInput,
  DateTimeInput,
  ExportButton,
  Filter,
  NumberField,
  FunctionField,
  DateField,
  EditButton,
  CreateButton,
  required,
  minLength,
  maxLength,
  SelectInput,
  BooleanInput,
  useRedirect,
  useRefresh,
  SelectArrayInput, FormDataConsumer
} from 'react-admin';
import COUPON_TYPES from '../constants/CouponTypes';
import { PLAN_CHOICES } from '../constants';

const CodeFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Code" source="code" alwaysOn />
      <BooleanInput source="isHundredPercent" label="100%-ын купон" alwaysOn />
  </Filter>
);

const CouponActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath} />
      <CreateButton basePath={`bulk${basePath}`} label="Create in bulk" />
    </Toolbar>
  );
};

export const CouponList = props => (
  <List {...props} filters={<CodeFilter/>} actions={<CouponActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Код" source="code" />
      <TextField label="Зорилго" source="description" />
      <NumberField label="Дүн" source="amount" />
      <SelectField label="Тооцох төрөл" source="type" choices={COUPON_TYPES} />      
      <FunctionField label="Ашигласан тоо" render={record => `${record.usedNumber}/${record.maxUses}`} />
      <TextField label="Эрхийн хугацаа" source="subscriptionPlanType" />
      <DateField label="Эхлэх огноо" source="startsAt" />
      <DateField label="Дуусах огноо" source="expiresAt" />
      <EditButton />
    </Datagrid>
  </List>
);

const CouponTitle = ({ record }) => {
  const { code } = record;
  return <span>Coupon {record ? `"${code}"` : ''}</span>;
};

export const CouponEdit = props => (
  <Edit title={<CouponTitle />} {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
            formData.shouldSendNotification && <TextInput label="Сонордуулгын текст" source="notificationText" validate={required()} />
        }
      </FormDataConsumer>
      <TextField label="ID" source="id" />
      <TextInput label="Код" source="code" validate={[required(), minLength(6), maxLength(8)]} helperText="6 - 8 урттай үсэг тоо холилдсон том үсгээр" />
      <TextInput label="Зорилго" source="description" />      
      <SelectInput label="Тооцох төрөл" source="type" choices={COUPON_TYPES} validate={required()} helperText="Үндсэн дүнгээс яаж тооцох, дүнтэй уялдаатай" />
      <NumberInput label="Дүн" source="amount" validate={required()} helperText="%: Үндсэн үнэ - (үндсэн үнэ * (дүн/100)); Дүнгээр: Үндсэн үнэ - дүн" />
      <NumberInput label="Ашиглах тоо" source="maxUses" validate={required()} />
      <NumberField label="Ашигласан тоо" source="usedNumber" />      
      <SelectArrayInput label="Эрхийн хугацаа" source="subscriptionPlanType" choices={PLAN_CHOICES} allowEmpty emptyText="any" />
      <DateTimeInput label="Эхлэх огноо" source="startsAt" validate={required()} />
      <DateTimeInput label="Дуусах огноо" source="expiresAt" validate={required()} />      
    </SimpleForm>
  </Edit>
);

export const CouponCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
            formData.shouldSendNotification && <TextInput label="Сонордуулгын текст" source="notificationText" validate={required()} />
        }
      </FormDataConsumer>
      <TextInput label="Код" source="code" validate={[required(), minLength(6), maxLength(8)]} helperText="6 - 8 урттай үсэг тоо холилдсон том үсгээр" />
      <TextInput label="Зорилго" source="description" />      
      <SelectInput label="Тооцох төрөл" source="type" choices={COUPON_TYPES} validate={required()} helperText="Үндсэн дүнгээс яаж тооцох, дүнтэй уялдаатай" />
      <NumberInput label="Дүн" source="amount" validate={required()} helperText="%: Үндсэн үнэ - (үндсэн үнэ * (дүн/100)); Дүнгээр: Үндсэн үнэ - дүн" />
      <NumberInput label="Ашиглах тоо" source="maxUses" validate={required()} />
      <NumberField label="Ашигласан тоо" source="usedNumber" emptyText="0" />      
      <SelectArrayInput label="Эрхийн хугацаа" source="subscriptionPlanType" choices={PLAN_CHOICES} allowEmpty emptyText="any" />
      <DateTimeInput label="Эхлэх огноо" source="startsAt" validate={required()} />
      <DateTimeInput label="Дуусах огноо" source="expiresAt" validate={required()} />  
    </SimpleForm>
  </Create>
);


export const BulkCouponCreate = props => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const onSuccess = () => {
    redirect('/coupons');
    refresh();
  };

  return(
    <Create onSuccess={onSuccess} {...props} title="Өгөгдсөн тоо ширхэгээр ижил купонуудыг үүсгэнэ">
      <SimpleForm>
        <NumberInput label="Тоо ширхэг" source="number" validate={required()} helperText="Өгөгдсөн тоо ширхэгээр ижил купонуудыг үүсгэнэ" />
        <NumberInput label="Купоны кодын урт" source="codeLength" validate={[required(), minLength(6), maxLength(8)]} defaultValue={8}/>
        <TextInput label="Зорилго" source="description" validate={required()} />      
        <SelectInput label="Тооцох төрөл" source="type" choices={COUPON_TYPES} validate={required()} helperText="Үндсэн дүнгээс яаж тооцох, дүнтэй уялдаатай" />
        <NumberInput label="Дүн" source="amount" validate={required()} helperText="%: Үндсэн үнэ - (үндсэн үнэ * (дүн/100)); Дүнгээр: Үндсэн үнэ - дүн" />
        <NumberInput label="Ашиглах тоо" source="maxUses" validate={required()} />
        <SelectArrayInput label="Эрхийн хугацаа" source="subscriptionPlanType" choices={PLAN_CHOICES} allowEmpty emptyText="any" />
        <DateTimeInput label="Эхлэх огноо" source="startsAt" validate={required()} />
        <DateTimeInput label="Дуусах огноо" source="expiresAt" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
