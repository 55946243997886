import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const TextFilter = (props) => (
    <Filter {...props}>
      <TextInput label="ID" source="id" alwaysOn />
      <ReferenceInput label="Хэрэглэгч" source="userId" reference="users" alwaysOn>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput label="Quiz" source="quizId" reference="lt/quizzes" alwaysOn>
        <AutocompleteInput optionText={choice => choice.title ? `${choice.title} (${choice.type})` : ''} />
      </ReferenceInput>
    </Filter>
);

export const LTQuizSessionList = props => (
    <List {...props} filters={<TextFilter/>} >
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField label="User Name" source="userId" reference="users">
        <TextField label="User Name" source="firstName" />
      </ReferenceField>
      <ReferenceField label="Quiz Name" source="quizId" reference="lt/quizzes">
        <TextField label="Quiz Name" source="title" />
      </ReferenceField>
      <TextField label="Remaining time" source="remainingTime" />
      <TextField label="Status" source="status" />
    </Datagrid>
  </List>
);

