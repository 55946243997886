import React, {useEffect, useState} from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  EditButton,
  DateField,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  NumberInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  DateInput,
  FormDataConsumer, Filter, BooleanInput,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import dataProvider from "../utils/dataProvider";
const getMentorEmail = mentor => `${mentor?.user?.email}`;
const fetchData = (mentorId) => {
  return dataProvider.getList('trainings', {
    "pagination": {
      "page": 1,
      "perPage": 500
    },
    "sort": {
      "field": "id",
      "order": "DESC"
    },
    "filter": {
      "mentorId": mentorId,
    }
  }).then(items => {
    return items.data.map(item => {
      return {
        id: item.id,
        name: item.title,
      }
    });
  });
}
const TrainingInput = props => {
  const { values } = useFormState();
  const { mentorId } = values;
  const [trainingChoices, setTrainingChoices] = useState();

  useEffect(() => {
    fetchData(mentorId || -1).then(choices => setTrainingChoices(choices));
  }, [mentorId])
  return (
    <SelectInput
      choices={trainingChoices}
      {...props}
    />
  );
};

const TextFilter = (props) => (
    <Filter {...props}>
      <ReferenceInput label="Mentor email" source="mentorId" reference="mentors" alwaysOn>
        <AutocompleteInput
            optionText={getMentorEmail}
            shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
            options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput label="Title" source="title" alwaysOn/>
    </Filter>
);

export const TimeslotList = props => (
  <List {...props} filters={<TextFilter/>}>
    <Datagrid optimized>
      <TextField label="Id" source="id" />
      <ReferenceField label="Mentor email" source="mentor.user.id" reference="users" sortable={false}>
        <TextField label="Mentor email" source="email" />
      </ReferenceField>
      <ReferenceField label="Training" source="trainingId" reference="trainings">
        <TextField label="Training" source="title" />
      </ReferenceField>
      <TextField label="Title" source="title" />
      <DateField label="Start date" source="starting" />
      <DateField label="End date" source="ending"/>
      <EditButton />
    </Datagrid>
  </List>
);

export const TimeslotEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextField source="id" />
      <TextInput label="Title" source="title" fullWidth/>
      <ReferenceInput label="Mentor email" source="mentorId" reference="mentors" fullWidth>
        <AutocompleteInput
            optionText={getMentorEmail}
            shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
            options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({formData, ...rest}) => (
            <TrainingInput source="trainingId"  />
        )}
      </FormDataConsumer>
      <NumberInput label="Price" helperText="Price" source="price"/>
      <Box display="flex">
        <Box flex={1} ml="0.5em">
          <DateInput label="Start date" helperText="Улаанбаатарын (+08:00) цагаар" source="starting" validate={[required()]}/>
        </Box>
        <Box flex={1} ml="0.5em">
          <DateInput label="End date" helperText="Улаанбаатарын (+08:00) цагаар" source="ending" validate={[required()]}/>
        </Box>
      </Box>
      <ArrayInput label="Өдөр болон цаг" source="entries" validate={[required()]}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData }) => {
              return (
                <>
                  <Box display="flex">
                    <SelectInput label="Өдөр" source={getSource('day')} choices={[
                      { id: 1, name: 'Monday' },
                      { id: 2, name: 'Tuesday' },
                      { id: 3, name: 'Wednesday' },
                      { id: 4, name: 'Thursday' },
                      { id: 5, name: 'Friday' },
                      { id: 6, name: 'Saturday' },
                      { id: 0, name: 'Sunday' },
                    ]} validate={[required()]} />
                    <TextInput label="Start time" source={getSource('startTime')} type="time" validate={[required()]} />
                    <TextInput label="End time" source={getSource('endTime')} type="time" validate={[required()]} />
                  </Box>
                  <Box display="flex">
                    <DateInput label="Start date" defaultValue={formData.starting} source={getSource('startDate')} validate={[required()]}/>
                    <DateInput label="End date" defaultValue={formData.ending} source={getSource('endDate')} validate={[required()]}/>
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const TimeslotCreate = props =>{
  return (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput label="Title" source="title" fullWidth/>
      <ReferenceInput label="Mentor email" source="mentorId" reference="mentors" fullWidth>
        <AutocompleteInput
          optionText={getMentorEmail}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({formData, ...rest}) => (
            <TrainingInput source="trainingId"  />
          )}
      </FormDataConsumer>
      <NumberInput label="Price" helperText="Price" source="price"/>
      <Box display="flex">
        <Box flex={1} ml="0.5em">
          <DateInput label="Start date" helperText="Улаанбаатарын (+08:00) цагаар" source="starting" validate={[required()]}/>
        </Box>
        <Box flex={1} ml="0.5em">
          <DateInput label="End date" helperText="Улаанбаатарын (+08:00) цагаар" source="ending" validate={[required()]}/>
        </Box>
      </Box>
      <ArrayInput label="Өдөр болон цаг" source="entries" validate={[required()]}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData }) => {
              return (
                <>
                  <Box display="flex">
                    <SelectInput label="Өдөр" source={getSource('day')} choices={[
                      { id: 1, name: 'Monday' },
                      { id: 2, name: 'Tuesday' },
                      { id: 3, name: 'Wednesday' },
                      { id: 4, name: 'Thursday' },
                      { id: 5, name: 'Friday' },
                      { id: 6, name: 'Saturday' },
                      { id: 0, name: 'Sunday' },
                    ]} validate={[required()]} />
                    <TextInput label="Start time" source={getSource('startTime')} type="time" validate={[required()]} />
                    <TextInput label="End time" source={getSource('endTime')} type="time" validate={[required()]} />
                  </Box>
                  <Box display="flex">
                    <DateInput label="Start date" defaultValue={formData.starting} source={getSource('startDate')} validate={[required()]}/>
                    <DateInput label="End date" defaultValue={formData.ending} source={getSource('endDate')} validate={[required()]}/>
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)
}
