import React from 'react';
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    TextInput,
    EditButton,
    ReferenceArrayInput,
    SelectArrayInput,
    FunctionField,
    SelectInput,
    NumberInput,
} from 'react-admin';

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const LTQuizBundleList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Title" source="title" />
      <FunctionField label="Үнэ" render={record => `${formatNumber(record.price)} MNT`} />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const QuizBundleTitle = ({ record }) => {
  const { id } = record;
  return <span>Quiz Bundle {record ? `"${id}"` : ''}</span>;
};

export const LTQuizBundleEdit = props => (
  <Edit title={<QuizBundleTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput fullWidth label="Title" source="title" />
      <TextInput fullWidth label="Нэмэлт тайлбар" source="additionalDescription" />
      <NumberInput label="Үнэ" source="price" />
      <ReferenceArrayInput label="Languages" source="languageTags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const LTQuizBundleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth label="Title" source="title" />
      <TextInput fullWidth label="Нэмэлт тайлбар" source="additionalDescription" />
      <NumberInput label="Үнэ" source="price" />
      <ReferenceArrayInput label="Languages" source="languageTags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
