import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  FileInput,
  FileField,
  ImageField,
  BooleanInput,
  Filter,
  FormDataConsumer,
  CreateButton,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { CONTENT_STATUS } from '../../constants';
import dataProvider from "../../utils/dataProvider";

const LingoJourneyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Гарчиг" source="title" alwaysOn /> 
    <TextInput label="Багцын ID" source="parentId" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={[
        { id: 'draft', name: 'драфтласан' },
        { id: 'published', name: 'нийтлэсэн' },
        { id: 'completed', name: 'дууссан' },
      ]}
      alwaysOn
    />
    <SelectInput
      label="Үндсэн багц эсэх"
      source="isParent"
      emptyText="Бүгд"
      choices={[
        { id: 'true', name: 'Тийм' },
        { id: 'false', name: 'Үгүй' },
      ]}
      alwaysOn
    />
    <ReferenceInput label="Хэл" source="lang" reference="languages" alwaysOn>
      <SelectInput optionText="name" optionValue="id" emptyText="Бүгд" />
    </ReferenceInput>
    <ReferenceInput label="Түвшин" source="lvl" reference="languageLevels" alwaysOn>
      <SelectInput optionText="level" optionValue="id" emptyText="Бүгд" />
    </ReferenceInput>
  </Filter>
);

const ListFilterInput = props => {
  const {isParent, parentChoices} = props;
  const optionParentRenderer = parent => `${parent.id} - ${parent.title}`;
  if(!isParent) {
    return (
      <React.Fragment>
          <SelectInput label="Үндсэн багц" optionText={optionParentRenderer} source="parentId" choices={parentChoices} helperText="Энэ багц нь үндсэн багц доторх дэд багц бол, үндсэн багцийг сонгох" />
      </React.Fragment>
    );
  }
  return null;
}

const LingoJourneyActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const LingoJourneyList = props => {
  return (
    <List {...props} filters={<LingoJourneyFilter/>} actions={<LingoJourneyActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <TextField label="Гарчиг" source="title" />
        <TextField label="Үндсэн багцын ID" source="parentId" />
        <TextField label="Хэл" source="Languages.0.code" sortable={false} />
        <TextField label="Түвшин" source="LanguageLevels.0.level" sortable={false} />
        <TextField label="Үндсэн багц эсэх" source="isParent" />
        <TextField label="Үнэ" source="price" />
        <TextField label="Дараалал" source="order" />
        <TextField label="Төлөв" source="status" />
        <EditButton />
      </Datagrid>
    </List>  
  )
};

const Title = ({ record }) => {
  const { title } = record;
  return <span>Lingo Journey {record ? `"${title}"` : ''}</span>;
};

export  const LingoJourneyEdit = props => {
  const [parentChoices, setParentChoices] = useState();

  useEffect(() => {
    const journeyId = parseInt(props.id, 10);

    function iterateChoices(choices, list, parentId, level) {
      for (let id in list[parentId]) {
        const item = list[parentId][id];
        choices.push({
          id: item.id,
          title: '*'.repeat(level * 4) + item.title,
        });
        iterateChoices(choices, list, id, level + 1);
      }
      return choices;
    }

    function buildChoices(list) {
      let map = {};
      for (let j of list) {
        if (!(j.parentId in map)){
          map[j.parentId] = {};
        }
        if (journeyId !== j.id && journeyId !== j.parentId){
          map[j.parentId][j.id] = j;
        }
      }

      return iterateChoices([], map, null, 0);
    }

    async function fetchData(){
      const journeyData = await dataProvider.getList('lingo/journeys', {
        "pagination": {
          "page": 1,
          "perPage": 500
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {
          "isParent": true,
        }
      });
      const choices = buildChoices(journeyData.data);
      setParentChoices(choices);
    }
    fetchData();
  }, [props])

  const optionTeacherRenderer = teacher => `${teacher.id} - ${teacher.name}`;

  return (
      <Edit title={<Title />} {...props}>
        <SimpleForm>
          <CloneButton />
          <TextInput disabled source="id" />
          <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
          <TextInput fullWidth label="Гарчиг" source="title" />
          <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
            <SelectInput optionText="level" />
          </ReferenceInput>
          <BooleanInput label="Үндсэн багц эсэх" helperText="Аль нэг хэл болон түвшингийн доторх багц бол сонгох" source="isParent" initialValue={false} />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <ListFilterInput isParent={formData && formData.isParent} parentChoices={parentChoices} {...rest} />
              )
            }}
          </FormDataConsumer>
          <SelectInput
              label="Төлөв"
              source="status"
              choices={[
                { id: 'draft', name: 'драфтласан' },
                { id: 'published', name: 'нийтлэсэн' },
                { id: 'completed', name: 'дууссан' },
              ]}
          />
          <NumberInput label="Үнэ" source="price" />
          <NumberInput label="Дараалал" source="order" />
          <ArrayInput label="Багш нар" source="journeyCoordinators" validate={[required()]}>
            <SimpleFormIterator>
              <TextInput disabled label="Багшын ID" source="id" />
              <ReferenceInput label="Багшын нэр" source="userId" reference="contentProviders" perPage={200}>
                <AutocompleteInput optionText={optionTeacherRenderer} optionValue="id" />
              </ReferenceInput>
              <BooleanInput label="Үндсэн багш эсэх" source="isPrimary" defaultValue={false} />
            </SimpleFormIterator>
          </ArrayInput>
          <TextField label="Шаардлагатай бол бөглөх хэсгүүд (Доорх бүгд)" source="" />
          <RichTextInput label="Танилцуулга" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
          <ArrayInput fullWidth label="Би юу сурах вэ?" source="outcomes">
            <SimpleFormIterator>
              <TextInput fullWidth label="Сэдэв" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput label="Шаардлагаууд" source="requirements">
            <SimpleFormIterator>
              <TextInput fullWidth label="Сэдэв" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput
              fullWidth
              label="Хичээлийн агуулга"
              source="includes">
            <SimpleFormIterator>
              <TextInput fullWidth label="Сэдэв" />
            </SimpleFormIterator>
          </ArrayInput>
          <ImageField source="thumbnail" title="Зураг" />
          <FileInput source="file" label="Зураг" accept="image/*">
            <FileField source="image" title="image" />
          </FileInput>
        </SimpleForm>
      </Edit>
  )
};

export const LingoJourneyCreate = props => {
  const [parentChoices, setParentChoices] = useState();

  useEffect(() => {
    function iterateChoices(choices, list, parentId, level) {
      for (let id in list[parentId]) {
        const item = list[parentId][id];
        choices.push({
          id: item.id,
          title: '*'.repeat(level * 4) + item.title,
        });
        iterateChoices(choices, list, id, level + 1);
      }
      return choices;
    }

    function buildChoices(list) {
      let map = {};
      for (let j of list) {
        if (!(j.parentId in map)){
          map[j.parentId] = {};
        }
        map[j.parentId][j.id] = j;
      }

      return iterateChoices([], map, null, 0);
    }

    async function fetchData(){
      const journeyData = await dataProvider.getList('lingo/journeys', {
        "pagination": {
          "page": 1,
          "perPage": 500
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {
          "isParent": true,
        }
      });
      const choices = buildChoices(journeyData.data);
      setParentChoices(choices);
    }
    fetchData();
  }, [])

  const optionTeacherRenderer = teacher => `${teacher.id} - ${teacher.name}`;

  return (
      <Create {...props}>
        <SimpleForm>
          <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
          <TextInput label="Гарчиг" source="title" />
          <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
            <SelectInput optionText="level" />
          </ReferenceInput>
          <BooleanInput label="Үндсэн багц эсэх" helperText="Аль нэг хэл болон түвшингийн доторх багц бол сонгох" source="isParent" initialValue={false} />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <ListFilterInput isParent={formData && formData.isParent} parentChoices={parentChoices} {...rest} />
              )
            }}
          </FormDataConsumer>
          <SelectInput
            label="Төлөв"
            source="status"
            choices={CONTENT_STATUS}
          />
          <NumberInput label="Үнэ" source="price" />
          <NumberInput label="Дараалал" source="order" />
          <ArrayInput label="Багш нар" source="journeyCoordinators" validate={[required()]}>
            <SimpleFormIterator>
              <TextInput disabled label="Багшын ID" source="id" />
              <ReferenceInput label="Багшын нэр" source="userId" reference="contentProviders" perPage={200}>
                <AutocompleteInput optionText={optionTeacherRenderer} />
              </ReferenceInput>
              <BooleanInput label="Үндсэн багш эсэх" source="isPrimary" defaultValue={false} />
            </SimpleFormIterator>
          </ArrayInput>
          <TextField label="Шаардлагатай бол бөглөх хэсгүүд (Доорх бүгд)" source="" />
          <RichTextInput label="Танилцуулга" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
          <ArrayInput
              fullWidth
              label="Хичээлийн агуулга"
              source="includes">
            <SimpleFormIterator>
              <TextInput fullWidth label="Сэдэв" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth label="Шаардлагууд" source="requirements">
            <SimpleFormIterator>
              <TextInput fullWidth label="Сэдэв" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth label="Би юу сурах вэ?" source="outcomes">
            <SimpleFormIterator>
              <TextInput fullWidth label="Сэдэв" />
            </SimpleFormIterator>
          </ArrayInput>
          <FileInput source="file" label="Зураг" accept="image/*">
            <FileField source="image" title="image" />
          </FileInput>
        </SimpleForm>
      </Create>
  );
}
