import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  TextField,
  TextInput,
  EditButton,
  FunctionField,
  SelectInput,
  BooleanInput,
  NumberField,
  ImageInput,
} from 'react-admin';

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

export const LTQuizTypeList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Code" source="code" />
      <TextField label="Title" source="title" />
      <NumberField label="Дараалал" source="order" />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const QuizTypeTitle = ({ record }) => {
  const { id } = record;
  return <span>Quiz Bundle {record ? `"${id}"` : ''}</span>;
};

export const LTQuizTypeEdit = props => (
  <Edit title={<QuizTypeTitle />} {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput disabled source="id" />
      <TextInput fullWidth label="Title" source="title" />
      <SelectInput
          label="Төрөл"
          source="code"
          choices={[
            { id: 'listening', name: 'listening' },
            { id: 'reading', name: 'reading' },
            { id: 'writing', name: 'writing' },
            { id: 'speaking', name: 'speaking' },
          ]}
      />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <TextInput label="Текст өнгө" source="textColor" type="color" />
      <TextInput label="Дэвсгэр өнгө" source="bgColor" type="color" />
      <ImageField source="thumbnail" label="Одоогийн зураг" />
      <ImageInput label="Зураг" source="file" accept="image/*">
        <ImageField source="thumbnail"/>
      </ImageInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const LTQuizTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} /> 
      <TextInput fullWidth label="Title" source="title" />
      <SelectInput
          label="Төрөл"
          source="code"
          choices={[
            { id: 'listening', name: 'listening' },
            { id: 'reading', name: 'reading' },
            { id: 'writing', name: 'writing' },
            { id: 'speaking', name: 'speaking' },
          ]}
      />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <TextInput label="Текст өнгө" source="textColor" type="color" />
      <TextInput label="Дэвсгэр өнгө" source="bgColor" type="color" />
      <ImageInput label="Зураг" source="file" accept="image/*">
        <ImageField source="thumbnail"/>
      </ImageInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
