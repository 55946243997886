import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
} from 'react-admin';

export const LingoQuizSessionList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField label="User Name" source="userId" reference="users">
        <TextField label="User Name" source="firstName" />
      </ReferenceField>
      <ReferenceField label="Quiz Name" source="quizId" reference="quizzes">
        <TextField label="Quiz Name" source="title" />
      </ReferenceField>
      <ReferenceField label="Question number" source="quizId" reference="quizzes">
        <TextField label="Question number" source="numberOfQuestion" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

