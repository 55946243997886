import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EmailField,
  SimpleForm,
  TextInput,
  EditButton,
  AutocompleteInput,
  ReferenceInput,
  Filter,
  FileInput,
  FileField,
  ImageField,
  SelectInput,
  FunctionField,
} from 'react-admin';
import {
  CONTENT_PROVIDER_TYPES,
} from '../constants/ContentProvider';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Нэр" source="name" alwaysOn />
  </Filter>
);

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const getTypeById = (id) => {
  const type = CONTENT_PROVIDER_TYPES.find(type => type.id === id);
  return type ? type.name : '';
}

export const ContentProviderList = props => (
  <List {...props} filters={<TextFilter />} actions={<Action props={props} />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="about" />
      <FunctionField
        label="Төрөл"
        render={record => getTypeById(record.type)}
      />
      <TextField source="services" />
      <TextField source="pricing" />
      <TextField source="workingHours" />
      <TextField source="contact" />
      <TextField source="address" />
      <EmailField source="web" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ContentProviderEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Хэрэглэгч" source="userId" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ImageField source="picture" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <TextInput source="name" />
      <TextInput source="about" />
      <SelectInput
        label="Төрөл"
        source="type"
        choices={CONTENT_PROVIDER_TYPES}
      />
      <TextInput source="services" />
      <TextInput source="pricing" />
      <TextInput source="workingHours" />
      <TextInput source="contact" />
      <TextInput source="address" />
      <TextInput source="web" />
    </SimpleForm>
  </Edit>
);

export const ContentProviderCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Хэрэглэгч" source="userId" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ImageField source="picture" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <TextInput source="name" />
      <TextInput source="about" />
      <SelectInput
        label="Төрөл"
        source="type"
        choices={CONTENT_PROVIDER_TYPES}
      />
      <TextInput source="services" />
      <TextInput source="pricing" />
      <TextInput source="workingHours" />
      <TextInput source="contact" />
      <TextInput source="address" />
      <TextInput source="web" />
    </SimpleForm>
  </Create>
);
