import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  ReferenceField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
} from 'react-admin';
import dataProvider from "../utils/dataProvider";

const iterateChoices = (choices, list, parentId, level) => {
  for (let id in list[parentId]) {
    const item = list[parentId][id];
    choices.push({
      id: item.id,
      name: '*'.repeat(level * 4) + item.name,
    });
    iterateChoices(choices, list, id, level + 1);
  }
  return choices;
}
const buildChoices = (list, entityId) => {
  let map = {};
  for (let j of list) {
    if (!(j.parentId in map)) {
      map[j.parentId] = {};
    }
    if (entityId !== j.id && entityId !== j.parentId && !j.parentId) {
      map[j.parentId][j.id] = j;
    }
  }
  return iterateChoices([{
    id: null,
    name: '---',
  }], map, null, 0);
}

const fetchData = async (entityId, cb) => {
  const majorFieldsData = await dataProvider.getList('majorFields', {
    "pagination": {
      "page": 1,
      "perPage": 500
    },
    "sort": {
      "field": "id",
      "order": "DESC"
    },
    "filter": {
      "isParent": true,
    }
  });
  const choices = buildChoices(majorFieldsData.data, entityId);
  cb(choices);
}

const CodeFilter = (props) => {
  const [parentChoices, setParentChoices] = useState();

  useEffect(() => {
    fetchData(-1, setParentChoices);
  }, [props])
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn/>
      <SelectInput
        alwaysOn
        defaultValue={null}
        label="Parent"
        optionText="name"
        source="parentId"
        choices={parentChoices}
      />
    </Filter>
  );
}

const MajorFieldActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath}/>
    </Toolbar>
  );
};

export const MajorFieldList = props => (
  <List {...props} filters={<CodeFilter/>} actions={<MajorFieldActions props={props}/>}>
    <Datagrid optimized>
      <TextField label="ID" source="id"/>
      <ReferenceField label="Parent" source="parentId" reference="majorFields">
        <TextField source="name"/>
      </ReferenceField>
      <TextField label="Нэр" source="name"/>
      <TextField label="Дараалал" source="rank"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const MajorFieldTitle = ({record}) => {
  const {name} = record;
  return <span>MajorField {record ? `"${name}"` : ''}</span>;
};

export const MajorFieldEdit = props => {
  const [parentChoices, setParentChoices] = useState();

  useEffect(() => {
    const entityId = parseInt(props.id, 10);
    fetchData(entityId, setParentChoices);
  }, [props])
  return (
    <Edit title={<MajorFieldTitle/>} {...props}>
      <SimpleForm>
        <TextField label="ID" source="id"/>
        <SelectInput
          defaultValue={null}
          label="Parent"
          optionText="name"
          source="parentId"
          choices={parentChoices}
        />
        <TextInput label="Нэр" source="name" validate={[required()]}/>
        <NumberInput label="Дараалал" source="rank" validate={[required()]}/>
      </SimpleForm>
    </Edit>
  )
};

export const MajorFieldCreate = props => {
  const [parentChoices, setParentChoices] = useState();

  useEffect(() => {
    fetchData(-1, setParentChoices);
  }, [props])

  return (
    <Create {...props}>
      <SimpleForm>
        <TextField label="ID" source="id"/>
        <SelectInput
          label="Parent"
          optionText="name"
          source="parentId"
          choices={parentChoices}
        />
        <TextInput label="Нэр" source="name" validate={[required()]}/>
        <NumberInput label="Дараалал" source="rank" validate={[required()]}/>
      </SimpleForm>
    </Create>
  )
};