import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  NumberInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  Filter,
  required,
  NumberField,
  ImageInput,
  useQuery,
  CloneButton,
  ReferenceInput,
  ReferenceField,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <ReferenceInput label="Багц" source="bundleId" reference="lt/quizBundles" alwaysOn>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <SelectInput
        label="Төрөл"
        source="type"
        alwaysOn
        choices={[
          { id: 'listening', name: 'listening' },
          { id: 'reading', name: 'reading' },
          { id: 'writing', name: 'writing' },
          { id: 'speaking', name: 'speaking' },
        ]}
    />
  </Filter>
);

export const LTQuizList = (props) => (
  <List {...props} filters={<TextFilter/>} >
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField label="Багц" source="bundleId" reference="lt/quizBundles">
        <TextField label="title" source="title" />
      </ReferenceField>
      <TextField label="Гарчиг" source="title" />
      <TextField label="Төрөл" source="type" />
      <NumberField label="Дараалал" source="order" />
      <TextField label="Хугацаа мин" source="totalDuration" />
      <TextField label="Асуултын тоо" source="numberOfQuestion" />
      <TextField label="Status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);
const QuizTypeField = () => {
  const { data: types } = useQuery({
    type: 'getList',
    resource: 'lt/quizTypes',
    payload: {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
    },
  });
  return (
      <SelectInput
          source="type"
          optionText="title"
          optionValue="code"
          choices={types}
          style={{ width: 255 }}
          validate={[required()]}
      />
  )
};
const QuizTitle = ({ record }) => {
  const { title } = record;
  return (
    <span>
Quiz
      {record ? `"${title}"` : ''}
    </span>
  );
};

export const LTQuizEdit = (props) => (
  <Edit title={<QuizTitle />} {...props}>
    <SimpleForm>
      <CloneButton />
      <TextInput disabled source="id" />
      <ReferenceInput label="Багц" source="bundleId" reference="lt/quizBundles" validate={[required()]}>
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput fullWidth label="Гарчиг" source="title" validate={[required()]} />
      <QuizTypeField/>
      <NumberInput label="Хугацаа мин" source="totalDuration" min={0} validate={[required()]} />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" validate={[required()]}  />
      <ArrayInput fullWidth source="instructions">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Заавар" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth source="sections" validate={[required()]}>
        <SimpleFormIterator>
          <TextInput fullWidth source="title" label="Section нэр" />
          <TextInput source="nbQuestion" label="Асуултын тоо" validate={[required()]} />
          <TextInput source="duration" label="үргэлжлэх хугацаа (мин)" />
          <NumberInput source="section" label="Section" validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageField source="thumbnailImage" title="Зураг" />
      <ImageInput source="file" label="Зураг" accept="image/*">
        <ImageField source="thumbnailImage" title="image" />
      </ImageInput>
      <NumberInput label="Асуултын тоо" source="numberOfQuestion" min={1} validate={[required()]} />
      <SelectInput
          label="статус"
          source="status"
          validate={[required()]}
          choices={[
            { id: 'draft', name: 'драфтласан' },
            { id: 'published', name: 'нийтлэсэн' },
          ]}
      />
    </SimpleForm>
  </Edit>
);

export const LTQuizCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth label="Гарчиг" source="title" validate={[required()]} />
      <ReferenceInput label="Багц" source="bundleId" reference="lt/quizBundles" validate={[required()]}>
        <SelectInput optionText="title" />
      </ReferenceInput>
      <QuizTypeField/>
      <NumberInput label="Хугацаа мин" source="totalDuration" min={0} validate={[required()]} />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" validate={[required()]}  />
      <ArrayInput fullWidth source="instructions">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Заавар" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth source="sections" validate={[required()]}>
        <SimpleFormIterator>
          <TextInput fullWidth source="title" label="Section нэр" />
          <TextInput source="nbQuestion" label="Асуултын тоо" validate={[required()]} />
          <TextInput source="duration" label="үргэлжлэх хугацаа (мин)" />
          <NumberInput source="section" label="Section" validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput source="file" label="Зураг" accept="image/*">
        <ImageInput source="thumbnailImage" title="image" />
      </ImageInput>
      <NumberInput label="Асуултын тоо" source="numberOfQuestion" min={1} validate={[required()]} />
      <SelectInput
          label="статус"
          source="status"
          validate={[required()]}
          choices={[
            { id: 'draft', name: 'драфтласан' },
            { id: 'published', name: 'нийтлэсэн' },
          ]}
      />
    </SimpleForm>
  </Create>
);
